<template>
	<div class="wrapper">
		<vxe-toolbar>
			<template v-slot:buttons>

				<vxe-button type="submit" icon="el-icon-plus" size="mini"  @click="goPage('add')"></vxe-button>
				<vxe-button type="submit" icon="el-icon-upload2" status="primary" size="mini"  @click="dataupload()">统计导出</vxe-button>

				<vxe-button type="submit" icon="el-icon-upload2" status="primary" size="mini"  @click="upload()">导出</vxe-button>
				<vxe-button status="my-orange" @click="showSearch(false)" v-if="seachStatus">隐藏查询</vxe-button>
				<vxe-button status="my-orange" icon="fa fa-search" @click="showSearch(true)" v-if="!seachStatus">查询</vxe-button>
			</template>
		</vxe-toolbar>
		<div class="scroll-box">
			<div class="search"  v-if="seachStatus">
			<vxe-form title-align="right" title-width="140" title-colon :data="searchData" @submit="searchEvent" @reset="searchReset" >
				<vxe-form-item title="选择查询日期" span="6">
					<vxe-input label-format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm" v-model="searchData.startSearchTime" placeholder="开始日期" type="datetime" clearable multiple></vxe-input>
				</vxe-form-item>
				<vxe-form-item span="4" >
					<vxe-input label-format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm" v-model="searchData.endSearchTime" placeholder="结束日期" type="datetime" clearable multiple></vxe-input>

				</vxe-form-item>
				<vxe-form-item title="老人姓名" span="7" v-click-outside='oldPersonoutSide'>
					<vxe-input v-model="searchData.serviceOldPersonName" placeholder="请输入姓名" @change="oldPersoninputChange" @blur = "oldPersoninputBlur" @focus = 'oldPersoninputfocus'></vxe-input>
					     <div class="autocomplete" v-show="isOpen">
							 <ul v-for="(name, i) in filteredNameData" :key="i" class="autocomplete-results"
								v-show="isOpen">
								<li @click="setResult(name)">{{ name }}</li>
								<li @click="deleteOldPersonRecord(name)"><i class="el-icon-close"></i></li>
							</ul>
						 </div>

				</vxe-form-item>

				<vxe-form-item title="工作人员姓名" span="7"  v-click-outside='workeroutSide'>
					<vxe-input v-model="searchData.serviceWorkPersonName" placeholder="请输入工作人员姓名" clearable  @change="workerinputChange" @blur="workerinputBlur"  @focus = 'workerinputfocus'></vxe-input>
					<div class="autocomplete"  v-show="isWorkerOpen">
						<ul v-for="(name, i) in workPersonNameList" :key="i" class="autocomplete-results" v-show="isWorkerOpen">
							<li @click="setWorkerResult(name)">{{ name }}</li>
							<li @click="deleteWorkPersonRecord(name)"><i class="el-icon-close"></i></li>
						</ul>
					</div>
				</vxe-form-item>
				<vxe-form-item title="人员类别" span="8">
					<vxe-select v-model="searchData.personType" placeholder="请选择人员级别" style="width: 150px">
              <vxe-option v-for="item in personCategoryList" :key="item.dictPidVal" :label="item.dictDesc" :value="item.dictPidVal"></vxe-option>
            </vxe-select>
				</vxe-form-item>
				<vxe-form-item align="center" span="24">
					<vxe-button type="submit" status="my-orange" icon="fa fa-check">查询</vxe-button>
					<vxe-button type="reset" status="my-orange" icon="fa fa-undo">重置</vxe-button>
				</vxe-form-item>
			</vxe-form>
		</div>

		<div class="table">

			<vxe-grid border stripe resizable auto-resize :columns="tableColumn" :data="tableData" :loading="loading"  ref="xGrid1" header-align="center">
				<template v-slot:name="{ row }">

					<p  v-if="searchData.serviceOldPersonId==''" class="link-a" @click="goPage('personDetail', row)">{{ row.serviceOldPersonName }}</p>
					<p  v-if="searchData.serviceOldPersonId!=''" class="link-a" @click="goPage('personDetail', row)">{{ row.serviceWorkPersonName }}</p>

				</template>
				<template v-slot:telephone="{row}">
					<p v-if="searchData.serviceOldPersonId==''">{{ row.serviceOldPersonTel}}</p>

					<p v-if="searchData.serviceOldPersonId!=''">{{ row.serviceWorkPersonTel }}</p>

				</template>
				<template v-slot:serviceWorkPersonName="{row}">
					<p style="color:#2d8cf0">{{ row.serviceWorkPersonName}}</p>

				</template>
				<template v-slot:time="{row}">
					<p>{{ row.serviceBeginTime }}</p>
					<p  style="color:red">{{ row.serviceEndTime }}</p>
				</template>

				<template v-slot:operation="{ row }">
					<vxe-button type="text" status="primary" v-if="row.ismore" @click="goPage('edit', row)">修改</vxe-button>
					<vxe-button type="text" status="primary" v-if="!row.ismore" @click="goPageDetai('xiangqing', row)">详情</vxe-button>
					<vxe-button type="text" status="primary"  v-show="row.status == '135_1'&&!row.ismore" @click="callBack(row)">回访</vxe-button>
					<vxe-button type="text"  icon="el-icon-plus" size="mini"  @click="row.ismore=true" v-if="!row.ismore"></vxe-button>
					<vxe-button type="text" status="primary" v-if="row.ismore" @click="remove(row.id)">删除</vxe-button>
					<vxe-button type="text"  size="mini" icon="el-icon-minus" @click="row.ismore=false" v-if="row.ismore"></vxe-button>
				</template>
			</vxe-grid>
		</div>
		<!-- 分页 -->
		<div class="page">
			<vxe-pager :current-page.sync="tablePage.currentPage" :page-size.sync="tablePage.pageSize" :page-sizes="tablePage.pageSizes"
			 :total="tablePage.totalResult" :layouts="layouts" @page-change="pageChange"></vxe-pager>
		</div>
		</div>

	</div>
</template>

<script>
import Vue from'vue'
import api from '@/store/API/api'

// 提交验证
Vue.directive('clickOutside', {
    // 初始化指令
    bind(el, binding) {
        function clickHandler(e) {
            // 这里判断点击的元素是否是本身，是本身，则返回
            if (el.contains(e.target)) {
                return false;
            }
            // 判断指令中是否绑定了函数
            if (binding.expression) {
                // 如果绑定了函数 则调用那个函数，此处binding.value就是handleClose方法
                binding.value(e);
            }
        }
        // 给当前元素绑定个私有变量，方便在unbind中可以解除事件监听
        el.__vueClickOutside__ = clickHandler;
        document.addEventListener('click', clickHandler);
    },
    update() { },
    unbind(el) {
        // 解除事件监听
        document.removeEventListener('click', el.__vueClickOutside__);
        delete el.__vueClickOutside__;
    },
});
	import {
		mapState,
		mapActions,
	} from "vuex";
	export default {
		props: {
			//老人id
			serviceOldPersonIdProp: {
				type: String,
				default: ''
			},
			//企业id
			serviceOrgIdProp: {
				type: String,
				default: ''
			},
			//从业人员di
			serviceWorkPersonIdProp: {
				type: String,
				default: ''
			},
  		},
		data() {
			return {
				loading: false,
				seachStatus: false,
				searchData: {
					serviceOldPersonId:'',
					serviceOldPersonName:'',
					serviceWorkPersonName:'',
					serviceOrgId:'',
					serviceWorkPersonId:'',
					endSearchTime:'',
					startSearchTime:'',
					personType:''

				},
				seachStatus: false,
				personCategoryList: [],
				isWorkerOpen:false, //从业人员搜索历史框
				isOpen:false,//老人搜索历史框
				arrowCounter:-1,
				filteredNameData:[],//老人搜索历史
				workPersonNameList:[],//从业人员搜索历史
				// 分页的参数配置
				tablePage: {
					totalResult: 0,
					currentPage: 1,
					pageSize: 10,
					align: "left",
					pageSizes: [10, 20, 50, 100, 500, 1000],
					perfect: true
				},
				tableColumn: [{
						type: 'seq',
						title: '序号',
						width: 60,
						align: "center"
					},
					{
						// field: 'serviceOldPersonName',
						title: this.serviceOldPersonIdProp==''?'服务对象':'从业人员',
						// showOverflow: true,
						align: "center",
						// minWidth: "100",
						slots: {
							default: 'name'
						}
					},
					{
						// field: 'serviceOldPersonTel',
						title: this.serviceOldPersonIdProp==''?'联系方式':'从业人员联系方式',
						// showOverflow: true,
						align: "center",
						slots: {
							default: 'telephone'
						}
						// minWidth: "160"
					},
					// {
					// 	// field: 'serviceOldPersonTel',
					// 	title:'工作人员',
					// 	align: "center",
					// 	slots: {
					// 		default: 'serviceWorkPersonName'
					// 	}
					// 	// minWidth: "160"
					// },
					{
						field: 'serviceOrgName',
						title: '服务公司',
						// showOverflow: true,
						align: "center",
						// minWidth: "200"
					},
					{
						field: 'serviceItemName',
						title: '服务项目',
						// showOverflow: true,
						align: "center",
						// minWidth: "200"
					},
										{
						field: 'statusName',
						title: '状态',
						showOverflow: true,
						align: "center",
						// minWidth: "200"
					},
					{
						field: 'serviceScore',
						title: '满意度',
						showOverflow: true,
						align: "center",
						// minWidth: "10"
					},
					{
						field: 'serviceTime',
						title: '服务时长',
						showOverflow: true,
						align: "center",
						// minWidth: "100"
					},
					{
						// field: 'time',
						title: '服务起止时间',
						// showOverflow: true,
						align: "center",
						minWidth: "130",
						slots: {
							default: 'time'
						}
					},
					{
						title: '操作',
						width: '200',
						fixed: 'right',

						// showOverflow: true,
						slots: {
							default: 'operation'
						},
					}
				],
				tableData: [],
				removeWorkPersonSearchItem:'',
				removeOldPersonSearchItem:''
			}
		},
		computed: {
			...mapState([
				"layouts"
			]),
		},
		created() {

			this.searchData.serviceWorkPersonId = this.serviceWorkPersonIdProp
			this.searchData.serviceOrgId = this.serviceOrgIdProp
			this.searchData.serviceOldPersonId = this.serviceOldPersonIdProp

			console.log(this.serviceOldPersonIdProp,this.serviceOldPersonIdProp!='','serviceOldPersonIdProp');
			// console.log(typeof(this.serviceOrgIdProp),'serviceOrgIdProp');
			// console.log(typeof(this.serviceWorkPersonIdProp),'serviceWorkPersonIdProp');


			this.initView()

		},
		methods: {
			...mapActions([
				'queryhomeServiceRecordList',
				// "getServeRecordsListServe",
				"delServeRecordsServe",
				'serchRecord',
				'getSysDictServe'
			]),
			initView() {
				this.getLists();
				this.getDicList()
			},
			// 是否显示查询功能
			showSearch(bool) {
				this.seachStatus = bool;
			},
			getDicList() {
      	this.getSysDictList('personCategory', 'personCategoryList') //人员类别
			},
			// 获取数据字典
    	getSysDictList(code, codeList) {
				let params = {
					typeCode: code,
				}
				this.getSysDictServe(params).then((res) => {
					if (res.code == 200) {
						this[codeList] = res.data
					}
				})
			},
			// 是否显示查询功能
			showSearch(bool) {
				this.seachStatus = bool;
			},
			goPage(type, itemData) {
				if(type == 'personDetail') {
					console.log('personDetail');
					this.$router.push({
						name: 'AddPerson',
						params: {
							type: 'detail',
							id: itemData.serviceOldPersonId
						}
					})
				}else{
					this.$router.push({
						name: 'AddServeRecords',
						params: {
							type: type,
							id: type == 'add' ? null : itemData.id
						},
						query: {
							item: type == 'add' ? null : JSON.stringify(itemData)
						}
					})
				}
			},
			goPageDetai(type, itemData){
				this.$router.push({
					name: 'serRecordDetail',
					params: {
						type: type,
						id: type == 'add' ? null : itemData.id
					},
					query: {
						item: type == 'add' ? null : JSON.stringify(itemData)
					}
				})
			},

			callBack(itemData){
				console.log(itemData);
					this.$router.push({
						name: 'AddRevisit',
						params: {
							id: itemData.id

						},
						query: {
							id: itemData.id,
							revisitStart:itemData.revisitStart,
              revisitDesc: itemData.revisitDesc,
              revisitImgInfoList: JSON.stringify(itemData.revisitImgInfoList)
						}
					})
			},

			getLists() {
				this.loading = true;
				this.queryhomeServiceRecordList({

						pageNum: this.tablePage.currentPage,
						pageSize: this.tablePage.pageSize,
						serviceOldPersonId: this.searchData.serviceOldPersonId,
						serviceOldPersonName:this.searchData.serviceOldPersonName,
						serviceWorkPersonName:this.searchData.serviceWorkPersonName,
						serviceOrgId:this.searchData.serviceOrgId,
						serviceWorkPersonId:this.searchData.serviceWorkPersonId,
						startSearchTime:this.searchData.startSearchTime,
						endSearchTime:this.searchData.endSearchTime,
						personType:this.searchData.personType==''?null:Array(this.searchData.personType)



				}).then(res => {
					if (res.code == 200) {
						this.tableData = res.data.records;

						this.tablePage.totalResult = +res.data.total;
						for (let i = 0; i < this.tableData.length; i++) {
     						this.$set(this.tableData[i],'ismore',false)
   }
						// this.tableData.map(item=>{
						// 	item.ismore = 'false'
						// })
						console.log(this.tableData)


					}
					this.loading = false;
				})
			},
			// 分页功能
			pageChange(item) {
				if (item.type == "size") {
					this.tablePage.currentPage = 1;
				} else {
					this.tablePage.currentPage = item.currentPage;
				}
				this.getLists();
			},
			searchEvent() {
				this.seachStatus = false;
				this.getLists();
			},
			searchReset() {
				this.searchData = {
					serviceOldPersonId:'',
					serviceOldPersonName:'',
					serviceWorkPersonName:'',
					serviceOrgId:'',
					serviceWorkPersonId:'',
					endSearchTime:'',
					startSearchTime:'',
					personType:''
				};
				this.getLists();
			},
			remove(id) {
				this.$XModal.confirm("您确定要删除该数据?").then(type => {
					if (type === "confirm") {
						this.delete(id)
					}
				})
			},
			delete(id) {
				this.delServeRecordsServe({
					id: id
				}).then(res => {
					if (res.code == 200) {
						this.$XModal.message({
							message: '删除成功',
							status: "success"
						});
						this.getLists()
					}
					this.loading = false;
				})
			},
			//输入框改变
			oldPersoninputChange(value){
				this.isOpen = true
			},
			//输入框失去焦点
			oldPersoninputBlur(){
				// this.isOpen = false
			},
			//输入框聚焦
			oldPersoninputfocus(){
				this.querySearchRecord()
				this.isOpen = true

			},
			//点击历史记录
			setResult(value){
				this.searchData.serviceOldPersonName =value
				this.isOpen = false
			},
			setWorkerResult(value){
				this.searchData.serviceWorkPersonName = value
				this.isWorkerOpen = false
			},
			//输入框搜索记录
			querySearchRecord(){
				let params = {
					removeWorkPersonSearchItem:this.removeWorkPersonSearchItem,
					removeOldPersonSearchItem :this.removeOldPersonSearchItem
				}
				this.serchRecord(params).then(res=>{
					this.filteredNameData= res.data.oldPersonNameList
					this.workPersonNameList = res.data.workPersonNameList
				})
			},
			//导出
			upload(){
				let myObj = {
					method: 'post',
					url: '/api' + api.homeServiceRecordExport,
					fileName: '服务记录',
					params: {
						pageNum: this.tablePage.currentPage,
						pageSize: this.tablePage.pageSize,
						serviceOldPersonId: this.searchData.serviceOldPersonId,
						serviceOldPersonName:this.searchData.serviceOldPersonName,
						serviceWorkPersonName:this.searchData.serviceWorkPersonName,
						serviceOrgId:this.searchData.serviceOrgId,
						serviceWorkPersonId:this.searchData.serviceWorkPersonId,
						startSearchTime:this.searchData.startSearchTime,
						endSearchTime:this.searchData.endSearchTime,
						personType:this.searchData.personType==''?null:Array(this.searchData.personType)


					},
				}
				this.$tools.exportMethod(myObj)

			},
			//统计导出
			dataupload(){
				let myObj = {
					method: 'post',
					url: '/api//homeServiceRecord/report-export' ,
					fileName: '服务记录报表导出',
					params: {
						pageNum: this.tablePage.currentPage,
						pageSize: this.tablePage.pageSize,
						serviceOldPersonId: this.searchData.serviceOldPersonId,
						serviceOldPersonName:this.searchData.serviceOldPersonName,
						serviceWorkPersonName:this.searchData.serviceWorkPersonName,
						serviceOrgId:this.searchData.serviceOrgId,
						serviceWorkPersonId:this.searchData.serviceWorkPersonId,
						startSearchTime:this.searchData.startSearchTime,
						endSearchTime:this.searchData.endSearchTime,
						personType:this.searchData.personType==''?null:Array(this.searchData.personType)

					},
				}
				this.$tools.exportMethod(myObj)

			},
			//点击div外部触发指令
			oldPersonoutSide(){
				this.isOpen = false

			},
			workeroutSide(){
				this.isWorkerOpen = false

			},
			//工作人员input改变
			workerinputChange(){
				this.isWorkerOpen = true
			},
			workerinputBlur(){
				this.isWorkerOpen = true
			},
			workerinputfocus(){
				this.querySearchRecord()
				this.isWorkerOpen = true
			},
			//删除单条老人搜索记录
			deleteOldPersonRecord(value){
				this.removeOldPersonSearchItem = value
				this.querySearchRecord()

			},
			//删除工作人员搜索记录单条
			deleteWorkPersonRecord(value){
				this.removeWorkPersonSearchItem = value
				this.querySearchRecord()

			}
		}
	}
</script>

<style lang="scss" scoped>
	.wrapper{
		height: 100%;
		display: flex;
		flex-direction: column;
		.scroll-box{
			flex: 1;
			overflow: auto;
		}
	  .datetime{
	    display: flex;
	    align-items: center;
	    span{
	      margin: 0 3px;
	    }
	  }
	}
	.sign i {
		margin-right: 3px;
		display: inline-block;
		width: 10px;
		height: 10px;
		border-radius: 50%;
	}
	.vxe-button.type--button {
		    border: 1px solid #f2f4ff9e !important;
	}
	.autocomplete-results{
		display: flex;
    justify-content: space-between;
	}
	.autocomplete{
		position: absolute;
		width: 100%;
		z-index: 200;
		background-color: #fff;
		border:solid 1px;
		border-top: none;
	}
	.autocomplete-results li :hover{
		cursor: pointer;
	}
</style>
